import React from "react";
import Container from "../components/Container";

const CheckPermissions = () => {
  return (
    <div>
      <Container>
        <h2>해당 페이지를 열람하실 수 없습니다.</h2>
      </Container>
    </div>
  );
};

export default CheckPermissions;
